import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultTemplate from "../templates/defaultTemplate.js";
export const _frontmatter = {
  "title": "Almost There",
  "slug": "optin"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Almost there...`}</h1>
    <p>{`You just need to confirm your interest (AKA, the "Confirmation Dance").`}</p>
    <p>{`Check your email.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      